import { memoizeOne } from '~commons/instance/memoize-one';
import { InstanceValues } from './types';

// This code breaks when used in compoent ssr (atob beeing undefined), so 'parseInstance'
// can be used only in controller
const atob =
  typeof self !== 'undefined'
    ? self.atob.bind(self)
    : (str: string) => global.Buffer.from(str, 'base64').toString('utf8');

// Wrapped in memoizeOne to feel more cozy while using it repeatedly
export const parseInstance = memoizeOne((instance: string): InstanceValues => {
  const [, base64data] = instance.split('.', 2);
  const data = atob(base64data);
  return JSON.parse(data);
});
