export const isShallowEqual = (a: any, b: any): boolean => {
  if (Object.is(a, b)) {
    return true;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((element, index) => element === b[index]);
  }
  if (a && b && a.constructor === Object && b.constructor === Object) {
    const keysOfA = Object.keys(a);
    if (keysOfA.length !== Object.keys(b).length) {
      return false;
    }
    return keysOfA.every((key) => Object.is(a[key], b[key]));
  }
  return false;
};
