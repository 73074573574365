import { isShallowEqual } from '~commons/services/is-shallow-equal';

// Poor mans implementation of popular 'memoizeOne' function, very usefull for memoization in selectors
export const memoizeOne = <T extends Function>(fn: T): T => {
  let lastCall: { type: 'NONE' } | { type: 'SOME'; args: any[]; result: any } = { type: 'NONE' };
  return ((...args: any[]) => {
    if (lastCall.type === 'SOME' && isShallowEqual(lastCall.args, args)) {
      return lastCall.result;
    }
    const result = fn(...args);
    lastCall = { type: 'SOME', args, result };
    return result;
  }) as any;
};
