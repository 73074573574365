export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export function assertDefined<T = any>(
  value: T,
  prefix: string = '',
): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(`${prefix}: value is not defined`);
  }
}

// https://github.com/microsoft/TypeScript/issues/37241#issuecomment-596806853
// Helps to narrow union by discrinating key: assert(myObject.type === 'SOME_TYPE')
export function assert(value: boolean): asserts value {
  if (!value) {
    throw new Error('Assertion failed');
  }
}

export function unreachable(param: never) {
  return new Error(`Unreachable case with value ${JSON.stringify(param)}`);
}

export type Subunion<T extends { type: string }, U extends T['type']> = T extends unknown
  ? T['type'] extends U
    ? T
    : never
  : never;
type UnionFromTuple<U extends unknown[]> = U[number];

type IsOfType = {
  <T extends { type: string }, U extends T['type'][]>(types: U, item: T): item is Subunion<
    T,
    UnionFromTuple<U>
  >;
  <T extends { type: string }, U extends T['type']>(type: U, item: T): item is Subunion<T, U>;
  // curried versions (works in functions like 'filter')
  <T extends { type: string }, U extends T['type'][]>(tags: U): (
    item: T,
  ) => item is Subunion<T, UnionFromTuple<U>>;
  <T extends { type: string }, U extends T['type']>(tag: U): (item: T) => item is Subunion<T, U>;
};

// General type utility function to narrow down discriminated union types
export const isOfType: IsOfType = ((tagOrTags: any, maybeItem: any): any => {
  const tags = Array.isArray(tagOrTags) ? tagOrTags : [tagOrTags];
  if (maybeItem) {
    return tags.includes(maybeItem.type);
  } else {
    return (item: any) => tags.includes(item.type);
  }
}) as any;

export const isDefined = <T>(item: T | null | undefined): item is T =>
  item !== null && item !== undefined;

export const keys: <T extends Object, K extends keyof T>(o: T) => K[] = Object.keys;

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

// Simplifies the output shown to the user
export type Compute<T> = T extends object
  ? {
      [P in keyof T]: Compute<T[P]>;
    }
  : T;

export type ComputeShallow<T> = T extends object
  ? {
      [P in keyof T]: T[P];
    }
  : T;

export type RequiredKeys<T, K extends keyof T> = Exclude<T, K> & { [key in K]-?: T[key] };

export function assertFields<T extends {}, K extends keyof T>(
  obj: T,
  fields: K[],
  objectName: string,
): asserts obj is RequiredKeys<T, K> {
  const notFoundFields = fields.filter((key) => obj[key] === undefined);
  if (notFoundFields.length) {
    throw new Error(
      `${objectName}: Expected fields ${fields.join(
        ', ',
      )}, but these fields where missing: ${notFoundFields.join(', ')}`,
    );
  }
}

// For action creators
export type VoidReturn<T extends Function> = T extends () => any
  ? () => void
  : T extends (payload: infer A) => any
  ? (payload: A) => void
  : never;

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type Result<T, E = string> = { type: 'ok'; value: T } | { type: 'error'; error: E };
export type Option<T> = { type: 'some'; value: T } | { type: 'none' };
